#header {
    position: absolute;
    width: 270px;
}

#header .pro-sidebar {
    height: 100vh;
}

#header .pro-sidebar {
    width: 100%;
    min-width: 100%;
}

#header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

#header .pro-sidebar .pro-menu .pro-menu-item.active{
  background-color: black;
}
  
  @media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
  }